<template>
    <OModal ref="JsonDataDialog">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-body row">
                    <div v-for="(field, index) in formData.fields" :key="index" :class="field.cssClass">
                        <component
                            :is="field.element"
                            :class="field.cssClass"
                            v-if="field.type === 'content'"
                            style="font-weight:bold;"
                        >
                            {{ field.caption }}
                        </component>
                        <label 
                            :for="field.name" 
                            v-if="field.type !== 'object' && field.type !== 'content' && field.type !== 'checkbox'"
                            :class="field.cssClass">
                            {{ field.caption }}
                        </label>
                        <input
                            v-if="field.type === 'text'"
                            :type="field.type"
                            :class="['form-control', field.cssClass]"
                            :disabled="readOnly"
                            :required="field.required"
                            v-model="field.value"
                        />
                        <div 
                            class="form-check"
                            v-if="field.type === 'checkbox'"
                        >
                                <input 
                                    :type="field.type" 
                                    :class="['form-check-input', field.cssClass]" 
                                    :disabled="readOnly"
                                    v-model="field.value" 
                                    :required="field.required" 
                                    :id="field.name"
                                >
                                <label 
                                    :for="field.name" 
                                    :class="field.cssClass"
                                    class="form-check-label"
                                >
                                    {{ field.caption }}
                                </label>
                            </div>
                        <div v-else-if="field.type === 'object'" class="row pb-2">
                            <div v-for="(subField, subIndex) in field.definition" :key="subIndex" :class="subField.cssClass" class="form-group">
                        <!-- <component :is="field.element" :class="field.cssClass"> -->
                        <!-- {{ field.caption }} -->
                        <!-- </component> -->
                        <!-- <div > -->
                                <component 
                                    :is="subField.element"
                                    v-if="subField.type === 'content'" 
                                    :class="subField.cssClass"
                                    class="pt-2"
                                    style="font-weight:bold;">
                                    {{ subField.caption }}
                                </component>
                                <label 
                                    :for="subField.name" 
                                    v-if="subField.type !== 'object' && subField.type !== 'content' && subField.type !== 'checkbox'"
                                    :class="subField.cssClass">
                                    {{ subField.caption }}
                                </label>
                                <input
                                    v-if="subField.type === 'text'"
                                    :type="subField.type"
                                    :class="['form-control', subField.cssClass]"
                                    :disabled="readOnly"
                                    :required="subField.required"
                                    v-model="subField.value"
                                />
                            <!-- Add more conditionals for other input types if needed -->
                                <div 
                                    class="form-check"
                                    v-if="subField.type === 'checkbox'"
                                >
                                    <input 
                                        :type="subField.type" 
                                        :class="['form-check-input', subField.cssClass]"
                                        :disabled="readOnly"
                                        v-model="subField.value" 
                                        :required="subField.required" 
                                        :id="subField.name"
                                    >
                                    <label 
                                        :for="subField.name" 
                                        :class="subField.cssClass"
                                        class="form-check-label"
                                    >
                                        {{ subField.caption }}
                                    </label>
                                </div>

                            <!-- Recursively handle nested fields -->
                                <template v-if="subField.type === 'object'">
                                    <div class="row">
                                        <div v-for="(nestedField, nestedIndex) in subField.definition" :class="[nestedField.cssClass]" :key="nestedIndex" class="row ms-2">
                                            <label :for="nestedField.name" class="ps-0">{{ nestedField.caption }}</label>
                                            <input
                                                v-if="nestedField.type === 'text'"
                                                :type="nestedField.type"
                                                :required="nestedField.required"
                                                :disabled="readOnly"
                                                v-model="nestedField.value"
                                                class="form-control"
                                            />
                                    <!-- Add more conditionals for other input types if needed -->
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary mt-2" data-bs-dismiss="modal" :title="$t('Close dialog')">{{ $t('Close') }}</button>
                    <button @click="saveJSON" v-if="!readOnly" class="btn btn-primary mt-2" :title="$t('Save')">{{ $t('Save') }}</button>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup>
    import { ref } from 'vue';

    const props = defineProps({
        jsonDataObject: Object
    }) 

    const jsonData = ref({});

    const formData = ref({});
    const JsonDataDialog = ref(null);

    const readOnly = ref(false);
    
    const showJsonDataDialog = (row, pReadOnly = false) => {
        jsonData.value = JSON.parse(props.jsonDataObject.current.JsonData);
        formData.value = JSON.parse(props.jsonDataObject.current.JsonConfig);
        readOnly.value = pReadOnly;

        console.log(pReadOnly);
        console.log(readOnly.value);

        if(props.jsonDataObject.current.JsonData)
        {
            initializeFormValues(formData.value.fields, jsonData.value);
        }
        JsonDataDialog.value.show()
    }
  
    const initializeFormValues = (fields, initialValues) => 
    {
        for (const field of fields) {
            if (field.type === 'object') {
                const fieldValue = initialValues[field.name];
                for (const subField of field.definition) {
                    if (fieldValue?.[subField.name] !== undefined) {
                        subField.value = fieldValue[subField.name];
                    }
                    if (subField.type === 'object') {
                        const subFieldValue = fieldValue?.[subField.name];
                        for (const nestedField of subField.definition) {
                            if (subFieldValue?.[nestedField.name] !== undefined) {
                                nestedField.value = subFieldValue[nestedField.name];
                            }
                        }
                    }
                }
            }
            else if (field.type !== 'object' && field.type !== 'content') {
                const fieldValue = initialValues[field.name];
                if (initialValues[field.name] !== undefined) {
                    field.value = fieldValue;
                }
            }
        }
    }


    const saveJSON = () => 
    {
        if(!readOnly.value)
        {
            const result = generateJSON(formData.value.fields, 0);

            props.jsonDataObject.current.JsonData = JSON.stringify(result)
            props.jsonDataObject.save();
            JsonDataDialog.value.hide();
        }
    };

    const generateJSON = (fields, level) => 
    {
        const result = {};
        for (const field of fields) 
        {
            if (field.type === 'object') 
            {
                if(level === 0)
                {
                    result[field.name] = generateJSON(field.definition, (level + 1));
                }
                else
                {
                    result[field.caption] = generateJSON(field.definition, (level + 1));
                }
            } else 
            {
                switch (field.type) {
                    // case 'content':
                        // result[field.caption] = field.caption;
                        // break;
                    case 'text':
                        result[field.caption] = field.value;
                        break;
                    case 'checkbox':
                        result[field.caption] = field.value;
                        break;
                }
            }
        }
        
        return result;
    };
    
    defineExpose({
        showJsonDataDialog
    })

</script>